import { captureException } from "@sentry/react";
import clsx from "clsx";
import { type ReactNode } from "react";
import { FiAlertCircle } from "react-icons/fi";
import styles from "./index.module.css";
import { capitaliseFirstLetter } from "@app/utils/capitalise-first-letter";

const getFriendlyError = (message: string) => {
	if (
		message.includes("unexpected value") ||
		message.includes("value is not a valid enumeration member")
	) {
		captureException(new Error(message));
		return "Unexpected error encountered. We will investigate and get back to you once solved.";
	}

	if (message === "value is not a valid email address") {
		return "Please provide a valid email address";
	}

	if (message === "ensure this value is greater than 0") {
		return "Please provide a value greater than 0";
	}

	if (message === "tax_number cannot exceed 10 characters") {
		return "A tax number cannot exceed 10 characters";
	}

	if (message === "value is not a valid phone number") {
		return "Please provide a valid phone number";
	}

	if (
		message === "shareholding_percent is required if relation is SHAREHOLDER"
	) {
		return "Please provide a shareholding percentage";
	}

	if (
		message === "designation is required if relation is AUTHORISED_SIGNATORY"
	) {
		return "Please provide a designation";
	}

	if (message === "none is not an allowed value") {
		return "This field is required";
	}

	if (message === "A description is required for source of funds 'Other'") {
		return "This field is required";
	}

	if (message === "ensure this value has at least 1 items") {
		return "This field is required";
	}

	if (message === "field required") {
		return "This field is required";
	}

	return capitaliseFirstLetter(message);
};

export const FieldError = ({ children }: { children: ReactNode }) => {
	return (
		<div className={clsx("field-error", styles.error)}>
			<FiAlertCircle size={16} style={{ minWidth: 16 }} />
			{typeof children === "string" ? getFriendlyError(children) : children}
		</div>
	);
};
