import { Helmet } from "react-helmet";
import { isDemo, isDev, isLocalhost, isPWA, isTest2 } from "./config/env";

export const MetaTags = () => {
	const favicon = isDemo
		? "/favicon-demo"
		: isTest2
			? "/favicon-test-2"
			: isDev || isLocalhost
				? "/favicon-test"
				: "/favicon";

	return (
		<Helmet>
			<link
				rel="icon"
				type="image/png"
				href={`${favicon}-48x48.png`}
				sizes="48x48"
			/>
			<link rel="icon" type="image/svg+xml" href={`${favicon}.svg`} />
			<link rel="shortcut icon" href={`${favicon}.ico`} />
			<link
				rel="apple-touch-icon"
				sizes="180x180"
				href={`${favicon}-180x180.png`}
			/>
			{isPWA && (
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
				/>
			)}
		</Helmet>
	);
};
