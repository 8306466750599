import { concatenateArrays, getMappedReasons } from "@app/utils";

import { EntityType } from "@app/types";
import { api } from "./api";
import type { MappedReasons } from "./models/api";
import type {
	ConfirmEmailFailureResponse,
	FieldInvalidationDetailResponse,
	FieldInvalidationResponse,
	LoginFailureResponse,
	RegisterFailureResponse,
	ResendConfirmationEmailFailureResponse,
	ResetPasswordFailureResponse,
	SendPasswordResetLinkFailureResponse,
} from "./models/auth";

export async function login(username: string, password: string) {
	try {
		const { status } = await api.post("auth/login/", {
			username: `${username}`,
			password: `${password}`,
		});

		return {
			isAuthentic: true,
			status: status,
		};
	} catch (exception: any) {
		const error = exception.data as LoginFailureResponse;
		const reasons = concatenateArrays(
			[!error.message && !error.detail ? ((error as string) ?? "") : ""],
			[(error.message as string) ?? ""],
			[(error.detail as string) ?? ""],
			error.non_field_errors,
			(error.username || []).map((x) => `username: ${x}`),
			(error.password || []).map((x) => `password: ${x}`),
		);

		return {
			isAuthentic: false,
			reasons,
		};
	}
}

export async function register(
	email: string,
	password: string,
	entityType: EntityType,
) {
	try {
		const { status } = await api.post("users/register/", {
			email: `${email}`,
			password: `${password}`,
			entity_type: `${entityType}`,
		});

		return status;
	} catch (exception: any) {
		const error = exception.data as RegisterFailureResponse;
		const reasons = [error.detail || ""];
		const fieldReasons: FieldInvalidationDetailResponse[] | undefined =
			Array.isArray(error.detail)
				? (error as FieldInvalidationResponse).detail
				: undefined;
		const mappedReasons: MappedReasons | undefined = fieldReasons
			? {
					email:
						fieldReasons
							?.filter((x) => x.loc.includes("email"))
							?.map((x) => x.msg || "") || [],
					password:
						fieldReasons
							?.filter((x) => x.loc.includes("password"))
							.map((x) => x.msg || "") || [],
				}
			: undefined;

		return {
			reasons,
			mappedReasons,
		};
	}
}

export async function resendConfirmationEmail(email: string) {
	try {
		const { status } = await api.post(
			`users/resend-confirmation-email/${email}/`,
		);

		return status;
	} catch (exception: any) {
		const error = exception.response
			.data as ResendConfirmationEmailFailureResponse;

		const reasons = concatenateArrays(
			[(error.message as string) ?? ""],
			[(error.detail as string) ?? ""],
			(error.email || []).map((x) => `email: ${x}`),
		);

		return {
			reasons,
		};
	}
}

export async function confirmEmail(uid: string, token: string) {
	try {
		const { status } = await api.get(`users/confirm-email/${uid}/${token}/`);

		return status;
	} catch (exception: any) {
		const error = exception.data as ConfirmEmailFailureResponse;
		const reasons = concatenateArrays(
			[(error.message as string) ?? ""],
			[(error.detail as string) ?? ""],
			(error.token || []).map((x) => `token: ${x}`),
			(error.uid || []).map((x) => `user id: ${x}`),
		);

		return {
			reasons,
		};
	}
}

export async function sendPasswordResetLink(email: string) {
	try {
		const { status } = await api.post(
			`users/send-password-reset-link/${email}/`,
		);

		return status;
	} catch (exception: any) {
		const error = exception.response
			.data as SendPasswordResetLinkFailureResponse;

		const reasons = concatenateArrays(
			[(error.message as string) ?? ""],
			[(error.detail as string) ?? ""],
			(error.email || []).map((x) => `email: ${x}`),
		);

		return {
			reasons,
		};
	}
}

export async function resetPassword(
	uid: string,
	token: string,
	password: string,
) {
	try {
		const { status } = await api.post("users/reset-password/", {
			uid: `${uid}`,
			token: `${token}`,
			password: `${password}`,
		});

		return status;
	} catch (exception: any) {
		const error = exception.data as ResetPasswordFailureResponse;
		const detailIsArray = Array.isArray(error.detail);
		const reasons = concatenateArrays(
			[(error.message as string) ?? ""],
			[(!detailIsArray ? (error.detail as string) : "") ?? ""],
		);
		const mappedReasons = getMappedReasons(error);

		return {
			reasons,
			mappedReasons,
		};
	}
}
