import { ApiErrors } from "@app/components/api-errors";
import { Checkbox } from "@app/components/checkbox";
import { Dropdown } from "@app/components/dropdown";
import { FieldError } from "@app/components/field-error";
import { Label } from "@app/components/label";
import { paths } from "@app/constants/paths";
import { fetcher } from "@app/fetcher";
import { useCountries } from "@app/hooks/use-countries";
import { api } from "@app/services";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { preload } from "swr";
import { Card } from "../card";
import { ContactNumberField } from "../contact-number-field";
import { DocumentField } from "../document-field";
import { OnboardingFooter } from "../onboarding-footer";
import { OnboardingLayout } from "../onboarding-layout";
import { ProvinceField } from "../province-field";
import { Row } from "../row";
import { TextField } from "../text-field";

import { useFormErrors } from "@app/hooks/use-form-errors";
import { IncompleteInformationModal } from "../incomplete-information-modal";
import styles from "./form.module.css";
import {
	CompanyBasics,
	CompanyBasicsUpdate,
	useCompanyBasics,
} from "./use-company-basics";

const mapFormToDTO = (data: CompanyBasics): CompanyBasicsUpdate => {
	return {
		...data,
		address: {
			...data.address!,
			registered_address: {
				...data.address.registered_address,
				province: data.address.registered_address?.province || null,
			},
			trading_address: data.address.same_addresses
				? null
				: {
						...data.address.trading_address!,
						province: data.address.trading_address?.province || null,
					},
		},
	};
};

export const Form = ({ activeClientId }: { activeClientId: number }) => {
	const navigate = useNavigate();
	const [showGeneralError, setShowGeneralError] = useState(false);
	const [targetPath, setTargetPath] = useState<string | null>(null);
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { data: countries } = useCountries();
	const { update, submit, data: companyBasicsData } = useCompanyBasics();
	const methods = useForm<CompanyBasics>({
		shouldFocusError: false,
		defaultValues: async () => {
			try {
				const { data } = await api.get<CompanyBasics>(
					`/onboarding/${activeClientId}/company-basics/`,
				);

				const countries = await preload<Array<string>>(
					"/utilities/countries/",
					fetcher,
				);

				return {
					...data,
					address: {
						...data.address,
						registered_address: {
							...data.address.registered_address,
							country: data.address.registered_address.country ?? countries[0],
						},
						trading_address: data.address.trading_address
							? {
									...data.address.trading_address,
									country:
										data.address?.trading_address?.country ?? countries[0],
								}
							: null,
					},
				};
			} catch {
				setShowGeneralError(true);
				return {} as CompanyBasics;
			}
		},
	});
	const { register, handleSubmit, watch, setValue, getValues } = methods;
	const {
		errors,
		apiErrors,
		clearErrors,
		clearApiFieldErrors,
		onErrors,
		onInvalid,
	} = useFormErrors(methods);

	const handlePartialSave = async () => {
		const values = getValues();
		const payload = mapFormToDTO(values);
		if (Object.keys(payload).length === 0) return;
		setIsSavingChanges(true);

		const errors = await update(payload);

		if (errors) {
			onErrors(errors);
		} else {
			setIsSaved(true);
			clearApiFieldErrors(payload);
		}
		setIsSavingChanges(false);
	};

	const onSubmit: SubmitHandler<CompanyBasics> = async (data) => {
		clearApiFieldErrors();
		setIsSubmitting(true);
		setIsSavingChanges(true);
		const errors = await submit(mapFormToDTO(data));

		if (errors) {
			onErrors(errors, true);
		} else {
			setIsSaved(true);
			navigate(paths().onboarding.business.companyDetails);
		}
		setIsSavingChanges(false);
		setIsSubmitting(false);
	};

	const handleNavigate = async (path: string) => {
		if (!path) return;
		const data = getValues();
		const errors = await submit(mapFormToDTO(data));
		if (errors) {
			onErrors(errors, true);
			setTargetPath(path);
		} else {
			navigate(path);
		}
	};

	register("registration_document", {
		required: "This field is required",
	});

	register("address.trading_address.proof_of_address", {
		required: !watch("address.same_addresses")
			? "This field is required"
			: false,
	});

	return (
		<OnboardingLayout
			step={0}
			variant="legal_entity"
			onStepNavigate={handleNavigate}
			showError={showGeneralError}
		>
			<FormProvider {...methods}>
				<form
					id="company-basics"
					className={styles.form}
					onSubmit={handleSubmit(onSubmit, onInvalid)}
				>
					{/* Details */}
					<Card>
						<Row>
							<TextField
								label="Company name*"
								placeholder="Enter your company name"
								error={errors.name}
								{...register("name", {
									required: "This field is required",
									onBlur: () => handlePartialSave(),
								})}
							/>

							<TextField
								label="Registration number*"
								placeholder="Enter your registration number"
								error={errors.registration_number}
								{...register("registration_number", {
									required: "This field is required",
									onBlur: () => handlePartialSave(),
								})}
							/>
						</Row>

						<Row>
							<TextField
								label="Tax number*"
								inputMode="decimal"
								placeholder="Enter your tax number"
								error={errors.tax_number}
								{...register("tax_number", {
									required: "This field is required",
									validate: (value) => {
										const taxNumber = value.replace(/\s/g, "");
										if (taxNumber.length !== 10) {
											return "Tax number must be 10 characters long";
										}
										if (!/^\d+$/.test(taxNumber)) {
											return "Tax number must only contain numbers";
										}
										return true;
									},
									onBlur: () => handlePartialSave(),
								})}
							/>
							<TextField
								label="VAT number"
								inputMode="decimal"
								placeholder="Enter VAT number (if applicable)"
								error={errors.vat_number}
								{...register("vat_number", {
									onBlur: () => handlePartialSave(),
								})}
							/>
						</Row>

						<DocumentField
							style={{ marginBottom: 0 }}
							type="registration_document"
							value={watch("registration_document")}
							onChange={async () => {
								if (!activeClientId) return;
								const result = await api.get<CompanyBasics>(
									`/onboarding/${activeClientId}/company-basics/`,
								);
								setValue(
									"registration_document",
									result.data.registration_document,
								);
								clearErrors("registration_document");
							}}
							tooltipWidth={320}
							tooltip={
								<>
									Examples include:
									<br />
									Certificate, Memorandum of Incorporation, and, if applicable,
									change of name or change of directors cerificate.
								</>
							}
							label="Registration document"
							error={errors.registration_document}
						/>
					</Card>

					{/* Contact Information */}
					<Card title="Contact Information">
						<Label htmlFor="contact_person_first_name">Contact person*</Label>
						<Row className={styles.nameRow}>
							<TextField
								placeholder="Enter their first name"
								error={errors.contact_person_first_name}
								{...register("contact_person_first_name", {
									required: "This field is required",
									onBlur: () => handlePartialSave(),
								})}
							/>
							<TextField
								placeholder="Enter their last name"
								error={errors.contact_person_last_name}
								{...register("contact_person_last_name", {
									required: "This field is required",
									onBlur: () => handlePartialSave(),
								})}
							/>
						</Row>
						<Row>
							<TextField
								label="Email*"
								placeholder="Enter their email address"
								error={errors.email_address}
								{...register("email_address", {
									required: "This field is required",
									onBlur: () => handlePartialSave(),
								})}
							/>

							<ContactNumberField
								placeholder="Enter their number"
								error={errors.contact_number}
								onBlur={() => handlePartialSave()}
							/>
						</Row>
					</Card>

					{/* Registered Address */}
					<Card
						title="Registered Address"
						actions={
							<div className={styles.sameAsCheckbox}>
								<Checkbox
									id="same-as"
									value={watch("address.same_addresses") ?? true}
									onChange={(value: boolean) => {
										setValue("address.same_addresses", value);
										if (!value) {
											if (countries)
												setValue(
													"address.trading_address.country",
													countries[0],
												);
											setValue(
												"address.trading_address.postal_code",
												companyBasicsData?.address.trading_address
													?.postal_code ?? "",
											);
											setValue(
												"address.trading_address.city",
												companyBasicsData?.address.trading_address?.city ?? "",
											);
											setValue(
												"address.trading_address.province",
												companyBasicsData?.address.trading_address?.province ??
													"",
											);
											setValue(
												"address.trading_address.other_province",
												companyBasicsData?.address.trading_address
													?.other_province ?? "",
											);
											setValue(
												"address.trading_address.address_1",
												companyBasicsData?.address.trading_address?.address_1 ??
													"",
											);
											setValue(
												"address.trading_address.address_2",
												companyBasicsData?.address.trading_address?.address_2 ??
													"",
											);
										}

										handlePartialSave();
									}}
								/>
								<label htmlFor="same-as">
									This is also our trading address
								</label>
							</div>
						}
					>
						<Row>
							<TextField
								label="Address line 1*"
								placeholder="Enter address"
								error={errors.address?.registered_address?.address_1}
								{...register("address.registered_address.address_1", {
									required: "This field is required",
									onBlur: () => handlePartialSave(),
								})}
							/>

							<TextField
								label="Address line 2 (optional)"
								placeholder="Enter address"
								error={errors.address?.registered_address?.address_2}
								{...register("address.registered_address.address_2", {
									onBlur: () => handlePartialSave(),
								})}
							/>
						</Row>

						<Row>
							<TextField
								label="City*"
								placeholder="Enter a city"
								error={errors.address?.registered_address?.city}
								{...register("address.registered_address.city", {
									required: "This field is required",
									onBlur: () => handlePartialSave(),
								})}
							/>

							<ProvinceField
								provinceFieldName="address.registered_address.province"
								otherFieldName="address.registered_address.other_province"
								countryFieldName="address.registered_address.country"
								error={
									errors.address?.registered_address?.province ||
									errors.address?.registered_address?.other_province
								}
								onChange={handlePartialSave}
							/>
						</Row>

						<Row>
							<TextField
								label="Postal code*"
								placeholder="Enter postal code"
								error={errors.address?.registered_address?.postal_code}
								{...register("address.registered_address.postal_code", {
									required: "This field is required",
									onBlur: () => handlePartialSave(),
								})}
							/>

							<div>
								<Label htmlFor="address.registered_address.country">
									Country*
								</Label>
								<Dropdown
									{...register("address.registered_address.country", {
										required: "This field is required",
										onChange: (event) => {
											setValue(
												"address.registered_address.country",
												event.value,
											);
											handlePartialSave();
											if (event.value)
												clearErrors("address.registered_address.country");
										},
									})}
									loading={!countries}
									value={watch("address.registered_address.country")}
									invalid={!!errors.address?.registered_address?.country}
									placeholder="Select an country"
									options={countries ?? []}
								/>
								{errors.address?.registered_address?.country && (
									<FieldError>
										{errors.address.registered_address.country.message}
									</FieldError>
								)}
							</div>
						</Row>

						{watch("address.same_addresses") && (
							<ApiErrors className={styles.apiErrors} errors={apiErrors} />
						)}
					</Card>

					{/* Trading Address */}
					{!watch("address.same_addresses") && (
						<Card title="Trading Address">
							<Row>
								<TextField
									label="Address line 1*"
									placeholder="Enter address"
									error={errors.address?.trading_address?.address_1}
									{...register("address.trading_address.address_1", {
										required: "This field is required",
										onBlur: () => handlePartialSave(),
									})}
								/>

								<TextField
									label="Address line 2 (optional)"
									placeholder="Enter address"
									error={errors.address?.trading_address?.address_2}
									{...register("address.trading_address.address_2", {
										onBlur: () => handlePartialSave(),
									})}
								/>
							</Row>

							<Row>
								<TextField
									label="City*"
									placeholder="Enter a city"
									error={errors.address?.trading_address?.city}
									{...register("address.trading_address.city", {
										required: "This field is required",
										onBlur: () => handlePartialSave(),
									})}
								/>

								<ProvinceField
									provinceFieldName="address.trading_address.province"
									otherFieldName="address.trading_address.other_province"
									countryFieldName="address.trading_address.country"
									error={
										errors.address?.trading_address?.province ||
										errors.address?.trading_address?.other_province
									}
									onChange={handlePartialSave}
								/>
							</Row>

							<Row>
								<TextField
									label="Postal code*"
									placeholder="Enter postal code"
									error={errors.address?.trading_address?.postal_code}
									{...register("address.trading_address.postal_code", {
										required: "This field is required",
										onBlur: () => handlePartialSave(),
									})}
								/>

								<div>
									<Label htmlFor="address.trading_address.country">
										Country*
									</Label>
									<Dropdown
										{...register("address.trading_address.country", {
											required: "This field is required",
											onChange: (event) => {
												setValue(
													"address.trading_address.country",
													event.value,
												);
												handlePartialSave();
												if (event.value)
													clearErrors("address.trading_address.country");
											},
										})}
										loading={!countries}
										value={watch("address.trading_address.country")}
										invalid={!!errors.address?.trading_address?.country}
										placeholder="Select an country"
										options={countries ?? []}
									/>
									{errors.address?.trading_address?.country && (
										<FieldError>
											{errors.address.trading_address.country.message}
										</FieldError>
									)}
								</div>
							</Row>

							<DocumentField
								style={{ marginBottom: 0 }}
								type="trading_proof_of_address"
								value={watch("address.trading_address.proof_of_address")}
								onChange={async () => {
									if (!activeClientId) return;
									const result = await api.get<CompanyBasics>(
										`/onboarding/${activeClientId}/company-basics/`,
									);
									if (result.data.address?.trading_address?.proof_of_address) {
										setValue(
											"address.trading_address.proof_of_address",
											result.data.address?.trading_address?.proof_of_address,
										);
										clearErrors("address.trading_address.proof_of_address");
									}
								}}
								label="Proof of address"
								error={errors.address?.trading_address?.proof_of_address}
							/>
							<ApiErrors className={styles.apiErrors} errors={apiErrors} />
						</Card>
					)}
				</form>
				<OnboardingFooter
					isSubmitting={isSubmitting}
					formId="company-basics"
					isSaving={isSavingChanges}
					hasSaved={isSaved}
				/>
			</FormProvider>

			{!!targetPath && (
				<IncompleteInformationModal
					path={targetPath}
					onCancel={() => setTargetPath("")}
				/>
			)}
		</OnboardingLayout>
	);
};
