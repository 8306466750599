import { Dialog } from "@app/components/dialog";

import { Button } from "@app/components/button";
import styles from "./index.module.css";

import {
	OnboardingStatus,
	useClientOnboardingStatus,
} from "@app/components/signed-in-layout/use-client-onboarding-status";
import { isPrimaryShareholdersEnabled } from "@app/constants/feature-flags";
import { paths } from "@app/constants/paths";
import { useAuth } from "@app/hooks/use-auth";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NeedHelpModal } from "../need-help-modal";
import activeSrc from "./active.svg";
import checkSrc from "./check.svg";
import completedSrc from "./completed.svg";

const getTitleFromStatus = (status: OnboardingStatus) => {
	const mappings: Record<OnboardingStatus, string> = {
		onboarding_individual: "Onboarding in progress",
		onboarding_business: "Onboarding in progress",
		processing_submission: "Onboarding form submitted",
		awaiting_shareholder_information: "Provide shareholder information",
		processing_shareholder_information: "Shareholder information submitted",
		awaiting_e_signature: "Sign your application",
		sending_documents_to_bank: "Application signed",
		awaiting_bank_account_opening: "Submitted to bank",
		awaiting_account_opening_confirmation:
			"Awaiting account opening confirmation",
		complete: "Onboarding complete",
	};

	return mappings[status] ?? "Onboarding in progress";
};

const checkStates = [
	"processing_submission",
	"processing_shareholder_information",
	"sending_documents_to_bank",
	"awaiting_bank_account_opening",
	"complete",
];

const signFormsStage = ["awaiting_e_signature", "sending_documents_to_bank"];

export const InProgressModal = () => {
	const navigate = useNavigate();
	const [showDialog, setShowDialog] = useState(true);
	const [showHelpDialog, setShowHelpDialog] = useState(false);
	const { onLogout } = useAuth();
	const { data } = useClientOnboardingStatus();
	const isMobile = useMediaQuery();

	if (!data) return;

	const lastCompleted = data.steps.findLastIndex(
		(step) => step.status === "Completed",
	);

	const isPrimaryShareholdersStep =
		data.status === "awaiting_shareholder_information" &&
		isPrimaryShareholdersEnabled;
	return (
		<>
			<Dialog
				isOpen={showDialog}
				size="xl"
				bottomsheet={isMobile}
				title="Onboarding in progress"
				actions={
					<>
						<Button
							variant={isPrimaryShareholdersStep ? "secondary" : "primary"}
							onClick={onLogout}
						>
							Log out
						</Button>
						{isPrimaryShareholdersStep ? (
							<Button
								onClick={() =>
									navigate(paths().onboarding.business.primaryShareholders)
								}
							>
								Add shareholders
							</Button>
						) : (
							<Button
								variant="tertiary"
								className={styles.helpButton}
								onClick={() => {
									setShowDialog(false);
									setShowHelpDialog(true);
								}}
							>
								Need help?
							</Button>
						)}
					</>
				}
			>
				<p
					className={styles.completedStep}
					style={{ gap: checkStates.includes(data.status) ? 12 : 4 }}
				>
					{checkStates.includes(data.status) ? (
						<img src={checkSrc} alt="" height={20} width={20} />
					) : (
						<span className={styles.nextStep}>Next step:</span>
					)}
					{getTitleFromStatus(data.status)}
				</p>
				{data.subtext.filter(Boolean).map((text) => (
					<p key={text} className={styles.description}>
						{text}
					</p>
				))}

				<ol className={styles.list}>
					{data.steps.map((current, index) => {
						const isActive =
							current.status === "Current" || lastCompleted === index;
						const isCompleted = current.status === "Completed";
						const isTodo = current.status === "To do";
						const showLine = index !== 0;
						const iconSrc = isCompleted ? completedSrc : activeSrc;

						const isWide = data.steps.length <= 3;

						return (
							<>
								{showLine && (
									<li
										className={styles.line}
										data-completed={isCompleted}
										style={{
											margin: isMobile
												? undefined
												: isWide
													? "9px -52px 0"
													: "9px -32px 0",
										}}
									/>
								)}
								<li
									key={current.label}
									className={styles.item}
									style={{
										maxWidth: isMobile ? undefined : isWide ? 145 : 105,
									}}
									data-active={isActive}
								>
									{isTodo ? (
										<div className={styles.todo} />
									) : (
										<img src={iconSrc} width={20} height={20} alt="" />
									)}

									<p>{current.label}</p>
								</li>
							</>
						);
					})}
				</ol>
			</Dialog>
			{showHelpDialog && (
				<NeedHelpModal
					variant={
						signFormsStage.includes(data.status) ? "default" : "condensed"
					}
					onBack={() => {
						setShowDialog(true);
						setShowHelpDialog(false);
					}}
				/>
			)}
		</>
	);
};
