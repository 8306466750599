import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";

import styles from "./confirm-changes-modal.module.css";

export const ConfirmChangesModal = ({
	onBack,
	onConfirm,
}: {
	onBack: () => void;
	onConfirm: () => void;
}) => {
	return (
		<Dialog
			size="sm"
			isOpen
			title="Confirm changes"
			onClose={onBack}
			actions={
				<>
					<Button
						className={styles.button}
						variant="secondary"
						onClick={onBack}
					>
						Back
					</Button>
					<Button className={styles.button} onClick={onConfirm}>
						Confirm
					</Button>
				</>
			}
		>
			<p className={styles.description}>
				Are you sure the recipient's updated details are correct?
			</p>
			<p className={styles.description}>
				Fraudsters can impersonate others, so we recommend verifying their
				details.
			</p>

			<Button
				href="https://futureforex.co.za/forex-faq/#how-do-i-verify-a-recipient"
				variant="tertiary"
				inline
				className={styles.howToVerify}
			>
				How do I verify a recipient?
			</Button>
		</Dialog>
	);
};
