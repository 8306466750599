import type { GetPaymentBopDocs } from "@app/entities";
import { useMediaQuery } from "@app/hooks/use-media-query";

import { twMerge } from "tailwind-merge";

import AdditionalDocIcon from "@app/assets/images/additional-doc.svg";
import SupportingDocIcon from "@app/assets/images/supporting-doc.svg";
import { Button } from "@app/components/button";
import { ErrorLabel } from "@app/components/error-label";
import { IconButton } from "@app/components/icon-button";

import { formatFileSize } from "@app/utils";
import { FiAlertCircle, FiCheckCircle, FiPlus, FiTrash2 } from "react-icons/fi";

import { SupportingDocument } from "@app/hooks/use-supporting-documents";
import styles from "./index.module.css";

const ADDITIONAL_DOCUMENTS_TYPE_ID = 1;

export const DocumentTypeContent = ({
	document,
	showValidation,
	paymentSupportingDocs,
	onShowUploadModal,
	onDownloadDocument,
	onDeleteDocument,
	allDocumentTypeIds,
}: {
	document: GetPaymentBopDocs;
	showValidation: boolean;
	paymentSupportingDocs?: SupportingDocument[] | null;
	onShowUploadModal: (
		documentTypeId: number,
		candidates?: SupportingDocument["documents"],
	) => void;
	onDownloadDocument: (doc: SupportingDocument["documents"][number]) => void;
	onDeleteDocument: (id?: string | number) => void;
	allDocumentTypeIds: number[];
}) => {
	const isMobile = useMediaQuery();

	const supportingDocuments = paymentSupportingDocs
		?.filter((current) => {
			const isMatchingType =
				current.document_type_id === document.documentTypeId;
			const isUngroupedDoc =
				document.documentTypeId === ADDITIONAL_DOCUMENTS_TYPE_ID &&
				!allDocumentTypeIds.includes(current.document_type_id);

			return isMatchingType || isUngroupedDoc;
		})
		.flatMap((current) => current.documents);

	const isEmpty = !supportingDocuments || supportingDocuments.length === 0;

	const isInError = document.required && showValidation && isEmpty;

	const borderStyle = isInError
		? "border-red-450"
		: isEmpty
			? "border-gray-110"
			: "border-teal-550";

	let icon = <img src={AdditionalDocIcon} width={24} height={24} alt="" />;

	const iconColor = isEmpty ? "#b0b0b0" : "#008e17";

	if (isEmpty) {
		if (document.required) {
			icon = <img src={SupportingDocIcon} width={24} height={24} alt="" />;
		}
	} else {
		icon = <FiCheckCircle color={iconColor} size={24} />;
	}

	return (
		<>
			<div
				className={twMerge(
					borderStyle,
					"flex flex-row justify-between rounded-2 border p-6 shadow-generic mobile:p-4",
				)}
			>
				<div className={styles.wrapper}>
					{icon}
					<div className={styles.content}>
						<div className={styles.titleContainer}>
							<p className={styles.title}>
								{document?.required
									? `${document?.documentName}*`
									: `${document?.documentName} (optional)`}
							</p>
							{isMobile && isEmpty && (
								<IconButton
									onClick={() =>
										onShowUploadModal(
											document.documentTypeId,
											supportingDocuments,
										)
									}
								>
									<FiPlus size={24} color="#56A7A2" />
								</IconButton>
							)}
						</div>

						{supportingDocuments && supportingDocuments?.length > 0 && (
							<ul className={styles.list}>
								{supportingDocuments.map((x) => (
									<li key={x.id} className={styles.listItem}>
										<div>
											{isMobile && (
												<p className={styles.documentName}>{x.document_name}</p>
											)}
											<div className={styles.item}>
												{!isMobile && (
													<>
														<p>{x.document_name}</p>
														<div className={styles.inlineDivider} />
													</>
												)}
												<p className={styles.fileSize}>
													{formatFileSize(
														x.document_size
															? Number.parseFloat(x.document_size)
															: 0,
													)}
												</p>
												<div className={styles.inlineDivider} />
												<Button
													noPadding
													variant="tertiary"
													className={styles.inlineButton}
													inline
													onClick={() => {
														if (onDownloadDocument) onDownloadDocument(x);
													}}
												>
													Download
												</Button>
												<div className={styles.inlineDivider} />
												{isMobile ? (
													<Button
														noPadding
														variant="tertiary"
														inline
														className={styles.inlineButton}
														onClick={() =>
															onShowUploadModal(
																document.documentTypeId,
																supportingDocuments,
															)
														}
													>
														Edit
													</Button>
												) : (
													<Button
														noPadding
														variant="tertiary"
														inline
														className={styles.inlineButton}
														onClick={() => {
															if (onDeleteDocument) onDeleteDocument(x.id);
														}}
													>
														Remove
													</Button>
												)}
											</div>
										</div>
										{isMobile && (
											<IconButton>
												<FiTrash2
													size={24}
													color="#56A7A2"
													onClick={() => {
														if (onDeleteDocument) onDeleteDocument(x.id);
													}}
												/>
											</IconButton>
										)}
									</li>
								))}
							</ul>
						)}
					</div>
				</div>
				{!isMobile && (
					<Button
						variant="secondary"
						className={styles.button}
						inline
						onClick={() =>
							onShowUploadModal(document.documentTypeId, supportingDocuments)
						}
					>
						{isEmpty ? "Add file" : "Change file"}
					</Button>
				)}
			</div>
			{isInError && (
				<ErrorLabel>
					<FiAlertCircle size={16} />
					<span className={twMerge("items-center", "flex w-fit")}>
						This document is required
					</span>
				</ErrorLabel>
			)}
		</>
	);
};
