import { Typography } from "@app/components/typography";

import styles from "../form-builder.module.css";
import type { HeadingPartialProps } from "../types";

export const HeadingPartial = (props: HeadingPartialProps) => {
	return (
		<Typography
			className={`${styles.heading} ${props.fieldRowClassName || ""}`}
			theme="displayMd"
		>
			{props.title}
		</Typography>
	);
};
