import { Typography } from "@app/components/typography";

import styles from "../form-builder.module.css";
import type { SubheadingPartialProps } from "../types";

export const SubheadingPartial = (props: SubheadingPartialProps) => {
	return (
		<Typography
			className={`${styles.heading} ${props.fieldRowClassName || ""}`}
			theme="displaySm"
		>
			{props.title}
		</Typography>
	);
};
