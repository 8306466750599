import { Typography } from "@app/components/typography";

import type { ViewProperties } from "./properties";

import styles from "./index.module.css";

const themes = {
	error: "Error",
	info: "Info",
	success: "Success",
	warn: "Warning",
};

export const ToastView = (props: ViewProperties) => {
	return (
		<div className={styles.container}>
			{props.theme.icon}
			{!props.content ? (
				<Typography theme="textLg">
					{themes[props.type ?? "success"]}
				</Typography>
			) : (
				props.content
			)}
		</div>
	);
};
