import { Title } from "@app/components/title";
import { links } from "@app/constants/links";
import { paths } from "@app/constants/paths";
import { useNavigate } from "react-router-dom";
import { ButtonGroup } from "../button-group";

import arbIconSrc from "./arb-icon.svg";
import forexIconSrc from "./forex-icon.svg";

import { Button } from "@app/components/button";
import styles from "./index.module.css";

export const SelectFogotPassword = () => {
	const navigate = useNavigate();
	return (
		<div>
			<Title>Reset your password</Title>
			<p className={styles.description}>
				Select which account you would like to do this for:
			</p>
			<ButtonGroup
				data={[
					{
						icon: forexIconSrc,
						title: "Forex",
						onClick: () => {
							navigate(paths().resetPassword());
						},
					},
					{
						icon: arbIconSrc,
						title: "Crypto Arbitrage",
						onClick: () => {
							navigate(paths().resetPassword("arb"));
						},
					},
				]}
			/>
			<Button
				className={styles.cancelButton}
				centered
				variant="tertiary"
				onClick={() => navigate(-1)}
			>
				Cancel
			</Button>
		</div>
	);
};
