import { ReactNode } from "react";

import warningIconSrc from "./warning-icon.svg";

import clsx from "clsx";
import styles from "./index.module.css";

export const WarningNote = ({
	title,
	description,
	children,
	className,
}: {
	title: ReactNode;
	description: ReactNode;
	children?: ReactNode;
	className?: string;
}) => {
	return (
		<div role="alert" className={clsx(styles.container, className)}>
			<img src={warningIconSrc} alt="" width={24} height={28} />
			<div className={styles.content}>
				<h4 className={styles.title}>{title}</h4>
				<p className={styles.description}>{description}</p>
				{children && <div className={styles.actions}>{children}</div>}
			</div>
		</div>
	);
};
