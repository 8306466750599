import { paths } from "@app/constants/paths";
import { useClients } from "@app/hooks/use-clients";
import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import { useCallback, useMemo } from "react";
import useSWR from "swr";

export type LinkableRelatedEntities = {
	id: number;
	related_entity_id: number;
	name: string;
	is_editable: boolean;
	complete: boolean;
};

export type RelatedEntities = {
	authorised_signatory: Array<LinkableRelatedEntities> | null;
	authorised_signatory_completed: boolean;
	director: Array<LinkableRelatedEntities> | null;
	director_completed: boolean;
	shareholder: Array<LinkableRelatedEntities> | null;
	shareholder_completed: boolean;
};

export type RelatedEntityVariant = keyof RelatedEntities;

export type RelatedEntityType =
	| "individual"
	| "pty_ltd"
	| "cc"
	| "trust"
	| "partnership"
	| "listed_company"
	| "npo"
	| "other";

export const useRelatedEntities = (variant?: RelatedEntityVariant) => {
	const { activeClientId } = useClients();
	const { data, isLoading, error, mutate } = useSWR<RelatedEntities>(
		variant && activeClientId
			? `/onboarding/${activeClientId}/related-entities/`
			: null,
	);

	const handleCreateEntity = useCallback(
		async (type: RelatedEntityType) => {
			try {
				const { data } = await api.post<{
					related_entity_id: number;
				}>(`/onboarding/${activeClientId}/related-entity/`, {
					entity_type: type,
				});
				await api.post("/onboarding/related-entity-link/", {
					client_id: activeClientId,
					related_entity_id: data.related_entity_id,
					relation: variant,
				});
				await mutate();
				return data.related_entity_id;
			} catch (error) {
				console.error(error);
			}
		},
		[activeClientId, mutate, variant],
	);

	const createEntityLink = async (entityId: number) => {
		try {
			await api.post("/onboarding/related-entity-link/", {
				client_id: activeClientId,
				related_entity_id: entityId,
				relation: variant,
			});
		} catch (error) {
			return getFormErrors(error);
		}
	};

	const linkableEntities = useMemo(() => {
		if (!data || !variant) return [];
		// Filter out to only show entities of other types that are not already linked
		const current = data[variant] as Array<LinkableRelatedEntities> | null;
		const validOptions = Object.keys(data)
			.filter(
				(key) =>
					key !== variant && Array.isArray(data[key as RelatedEntityVariant]),
			)
			.flatMap(
				(key) =>
					data[
						key as RelatedEntityVariant
					] as Array<LinkableRelatedEntities> | null,
			)
			.filter(
				(entity) =>
					!!entity &&
					entity.name?.trim() &&
					!current?.some(
						(c) => c.related_entity_id === entity.related_entity_id,
					),
			);

		const distinctIds = Array.from(
			new Set(validOptions.map((entity) => entity?.related_entity_id)),
		);

		const options = distinctIds.map((id) =>
			validOptions.find((entity) => entity?.related_entity_id === id),
		) as LinkableRelatedEntities[];
		return options;
	}, [variant, data]);

	const allLinkableEntities = useMemo(() => {
		if (!data) return [];
		return Object.values(data)
			.flat(1)
			.filter(Boolean) as LinkableRelatedEntities[];
	}, [data]);

	const entitiesForVariant = useMemo(
		() =>
			(data && variant
				? (data[variant] ?? [])
				: []) as LinkableRelatedEntities[],
		[data, variant],
	);

	return {
		data,
		isLoading,
		error,
		linkableEntities,
		allLinkableEntities,
		entitiesForVariant,
		mutate,
		createEntity: handleCreateEntity,
		getPathForEntity: async (
			entityId: number,
			currentVariant: keyof RelatedEntities,
		) => {
			if (!data) return;
			const targetVariant = Object.keys(data)
				.filter(
					(variant) =>
						variant !== currentVariant &&
						Array.isArray(data[variant as RelatedEntityVariant]),
				)
				.find((variant) =>
					(
						data[
							variant as keyof RelatedEntities
						] as Array<LinkableRelatedEntities> | null
					)?.some((entity) => entity.related_entity_id === entityId),
				) as keyof RelatedEntities | undefined;
			const path =
				targetVariant === "director"
					? paths().onboarding.business.directors
					: targetVariant === "shareholder"
						? paths().onboarding.business.shareholders
						: paths().onboarding.business.signatories;
			return path;
		},
		submitOnboarding: async () => {
			try {
				await api.post(`/onboarding/${activeClientId}/submit/`);
			} catch (error) {
				return getFormErrors(error);
			}
		},
		createEntityLink,
		deleteEntity: async (
			relatedEntityLinkId?: number,
			relatedEntityId?: number,
		) => {
			try {
				if (relatedEntityLinkId) {
					await api.delete(
						`/onboarding/related-entity-link/${relatedEntityLinkId}/`,
					);
				}

				if (relatedEntityId) {
					await api.delete(`/onboarding/related-entity/${relatedEntityId}/`);
				}
			} catch (error) {
				return getFormErrors(error);
			}
		},
		submit: async () => {
			try {
				await api.post(
					`/onboarding/${activeClientId}/submit-related-entities/`,
					{
						relation: variant,
					},
				);
			} catch (error) {
				return getFormErrors(error);
			}
		},
	};
};
