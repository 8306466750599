import { useClients } from "@app/hooks/use-clients";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const useUnsupportedBrowserRedirect = () => {
	const params = useParams<{ clientId?: string }>();
	const isIE = !!window.navigator.userAgent.match(/MSIE|Trident/);

	useEffect(() => {
		if (isIE) {
			window.location.href = params.clientId
				? `/${params.clientId}/unsupported-browser`
				: "/unsupported-browser";
		}
	}, [isIE, params.clientId]);
};
