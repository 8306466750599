import { Button } from "@app/components/button";
import { FormBuilder, FormBuilderProps } from "@app/components/form-builder";
import { Typography } from "@app/components/typography";

import { Dialog } from "@app/components/dialog";

import { MappedReasons } from "@app/services";
import { Control, FieldErrors } from "react-hook-form";
import styles from "./edit-nickname-modal-view.module.css";

export const EditNicknameModalView = (props: {
	isEditNickname?: boolean;
	loading?: boolean;
	nickname?: string;
	recipientId?: number;
	onNicknameSaved: () => void;
	onCancel: () => void;
	errors?: FieldErrors<any>;
	formControl: Control<any, any>;
	formInputs: FormBuilderProps.FormInputProps[][];
	isFullScreen?: boolean;
	isValid: boolean;
	isDirty: boolean;
	mappedReasons?: MappedReasons;
	title: string;
	handleSubmit: (
		e?: React.BaseSyntheticEvent<object, any, any> | undefined,
	) => Promise<void>;
}) => {
	return (
		<Dialog
			isOpen
			fullscreen={props.isFullScreen || false}
			onClose={props.onCancel}
			size="xl"
			title={props.isEditNickname ? "Edit nickname" : "Add a nickname"}
			description="Easily identify and manage this recipient with a personalised label."
			actions={
				<>
					<Button type="reset" variant="secondary" onClick={props.onCancel}>
						Cancel
					</Button>
					<Button
						form="edit-nickname"
						type="submit"
						disabled={!props.isValid || !props.isDirty}
					>
						Save
					</Button>
				</>
			}
		>
			<form id="edit-nickname" onSubmit={props.handleSubmit} noValidate>
				<Typography className={styles.editNicknameText} theme="textSm">
					Nickname
				</Typography>
				<FormBuilder
					formTitle={props.title}
					errors={props.errors}
					mappedReasons={props.mappedReasons}
					formInputs={props.formInputs}
					formControl={props.formControl}
				/>
			</form>
		</Dialog>
	);
};
