import { ReactNode } from "react";

import { FormBuilder, FormBuilderProps } from "@app/components/form-builder";

import { ApiErrors } from "@app/components/api-errors";
import { Subheading } from "@app/components/subheading";
import { MappedReasons } from "@app/services";
import { Control, FieldErrors, UseFormTrigger } from "react-hook-form";
import "./recipient-details-form.css";

export const RecipientAddressForm = ({
	showError = true,
	...props
}: {
	addressFormInputs: FormBuilderProps.FormInputProps[][];
	apiErrors?: ReactNode[];
	detailsFormInputs: FormBuilderProps.FormInputProps[][];
	errors?: FieldErrors<any>;
	formControl: Control<any, any>;
	isEdit: boolean;
	isIndividual: boolean;
	isValid: boolean;
	isDirty: boolean;
	recipientTypeOptions: {
		type: string;
		label: string;
		icon: string;
	}[];
	loading?: boolean;
	mappedReasons?: MappedReasons;
	recipientType?: string;
	title: string;
	trigger: UseFormTrigger<any>;
	onBack: () => void;
	onCancel: () => void;
	showError?: boolean;
	onSelectRecipientType: (recipientType: string) => void;
}) => {
	return (
		<div className="recipient-details-form">
			{showError && props.apiErrors && props.apiErrors?.length > 0 && (
				<ApiErrors autoFocus errors={props.apiErrors} />
			)}

			<Subheading>Recipient Address</Subheading>

			<div className="form-builder-wrapper">
				<FormBuilder
					formTitle={props.title as string}
					errors={props.errors}
					mappedReasons={props.mappedReasons}
					formInputs={props.addressFormInputs}
					formControl={props.formControl}
				/>
			</div>
		</div>
	);
};
