import { useNavigate } from "react-router-dom";

import { Button } from "@app/components/button";
import { NoTransactionsBlock } from "@app/components/no-transactions-block";
import { paths } from "@app/constants/paths";
import { type ListTransaction } from "@app/entities";

import { CountTag } from "@app/components/count-tag";
import { PaymentTypeCell } from "@app/components/payment-type-cell";
import { SortableTable } from "@app/components/sortable-table";
import { dateFormats } from "@app/constants/date-formats";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { toDayjs } from "@app/lib/date";
import styles from "./index.module.css";
import { TransactionList } from "./transaction-list";

export const DashboardCompletedTransactions = ({
	count,
	data = [],
	isLoading = false,
}: {
	isLoading?: boolean;
	data?: ListTransaction[];
	count?: number;
}) => {
	const isMobile = useMediaQuery();
	const navigate = useNavigate();

	const formattedData = data.map((x: ListTransaction) => {
		return {
			id: x.id,
			date: toDayjs(x.date, dateFormats.reverseIso8601).format(
				dateFormats.paddedDayShortMonthYear,
			),
			recipient: x.recipient,
			recipientDisplay: (
				<>
					{x.recipient === "N/A" ? (
						<div className={styles.emptyCell}>{x.recipient}</div>
					) : (
						x.recipient
					)}
				</>
			),
			zarAmount: x.zarAmount,
			zarAmountDisplay:
				x.zarAmount !== "" ? (
					x.zarAmount
				) : (
					<div className="empty-table-cell">-</div>
				),
			fxAmount: x.fxAmount,
			fxAmountDisplay: (
				<>
					{x.fxAmount !== "" ? (
						x.fxAmount
					) : (
						<div className={styles.emptyCell}>-</div>
					)}
				</>
			),
			fxCurrency: x.fxCurrency,
			paymentType: x.paymentType,
			tablePaymentType: <PaymentTypeCell type={x.paymentType} />,
			view: (
				<Button
					size="sm"
					variant="secondary"
					onClick={() => {
						navigate(paths().viewTransaction(x.id));
					}}
				>
					View
				</Button>
			),
		};
	});
	const noData = !data || data.length === 0;
	const showCount = count !== null && count !== undefined;

	return (
		<div className={styles.container}>
			<div className={styles.heading}>
				<h3 className={styles.title}>Complete</h3>
				{showCount && <CountTag>{count}</CountTag>}
			</div>
			{noData ? (
				isLoading ? (
					<div className={styles.loadingBlock} />
				) : (
					<NoTransactionsBlock />
				)
			) : (
				<div className={styles.content}>
					{isMobile ? (
						<TransactionList data={data} />
					) : (
						<SortableTable
							sortField="transaction_date"
							sortOrder={-1}
							data={formattedData.slice(0, 3)}
							columns={[
								{
									field: "date",
									header: "Date",
								},
								{
									field: "recipientDisplay",
									header: "Recipient",
								},
								{
									field: "zarAmountDisplay",
									header: "ZAR Amount",
								},
								{
									field: "tablePaymentType",
									header: "Type",
								},
								{
									field: "fxAmountDisplay",
									header: "FX Amount",
								},
								{
									field: "view",
									width: 94,
								},
							]}
						/>
					)}

					<Button
						centered
						variant="secondary"
						onClick={() => {
							navigate(paths().transactions());
						}}
					>
						View all transactions
					</Button>
				</div>
			)}
		</div>
	);
};
