import type { Dropdown, DropdownProps } from "primereact/dropdown";
import { type ReactNode, useEffect, useRef, useState } from "react";
import { FieldErrors, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import {
	Button,
	FormBuilder,
	type FormBuilderProps,
	Typography,
} from "@app/components";
import type {
	DropdownOption,
	WithdrawalBankAccount,
	WithdrawalDetails,
	WithdrawalForm,
	WithdrawalType,
} from "@app/entities";
import {
	useGetWithdrawOptions,
	useGetWithdrawalBankAccounts,
} from "@app/helpers";
import type { RootState } from "@app/redux";

import { Dialog } from "@app/components/dialog";
import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { Note } from "@app/components/note";
import { useClients } from "@app/hooks/use-clients";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { FiCheck, FiPlus } from "react-icons/fi";
import { twMerge } from "tailwind-merge";
import styles from "./index.module.css";

import "./withdraw-modal.css";

const splitter = "___SPLITTER____";
const DEFAULT_FEE = "ZAR 20.00";
const DEFAULT_WITHDRAW_TYPE = "standard";

export const WithdrawModal = (props: {
	isOpen: boolean;
	resetValues: boolean;
	onAddBankAccount: () => void;
	onClose: () => void;
	onManageAccounts: () => void;
	onSubmit: (withdrawalDetails: WithdrawalDetails) => void;
	onResetErrors?: () => void;
	initialErrors?: FieldErrors<WithdrawalForm>;
}) => {
	const dropdownRef = useRef<Dropdown>(null);
	const [isFocused, setIsFocused] = useState(false);
	const isMobile = useMediaQuery();

	const {
		control,
		handleSubmit,
		setValue,
		reset,
		clearErrors,
		formState: { errors },
	} = useForm<WithdrawalForm>({
		errors: props.initialErrors,
		defaultValues: {
			withdrawalType: DEFAULT_WITHDRAW_TYPE,
		},
		mode: "onChange",
	});

	const [getWithdrawalBankAccounts] = useGetWithdrawalBankAccounts();

	const [getWithdrawOptions] = useGetWithdrawOptions();

	const { activeClientId } = useClients();

	const { withdrawalBankAccounts, withdrawOptions } = useSelector(
		(rootState: RootState) => rootState.withdrawals,
	);

	const handleFocus = () => setIsFocused(true);
	const handleBlur = () => {
		setIsFocused(false);
	};

	const fetchWithdrawalInfo = () => {
		getWithdrawalBankAccounts();
		getWithdrawOptions();
	};

	const handleChangeAmount = (value: string) => {
		const amount = value.replace(/[,a-zA-Z]/g, "");

		if (
			typeof amount === "string" &&
			!!amount &&
			!Number.isNaN(Number(amount))
		) {
			setValue("amount", "\u200B", { shouldTouch: true });
			props.onResetErrors?.();
			clearErrors("amount");
			setTimeout(
				() =>
					setValue("amount", Number.parseFloat(amount).toLocaleString("en"), {
						shouldTouch: true,
					}),
				0,
			);
		} else if (amount === "") {
			setValue("amount", "\u200B", { shouldTouch: true });
			setTimeout(() => setValue("amount", "", { shouldTouch: true }), 0);
		}
	};

	const bankAccountOptions = () => {
		if (withdrawalBankAccounts?.items) {
			return withdrawalBankAccounts.items.map((x: WithdrawalBankAccount) => {
				if (x.bank && x.accountNumber) {
					return {
						value: x.accountNumber,
						label: x.bank + splitter + x.accountNumber,
					};
				}

				return { label: "", value: "" };
			});
		}
		return [{ label: "", value: "" }];
	};

	const bankAccountItemTemplate = (
		option: DropdownOption,
		value: any,
	): ReactNode => {
		const displayOptions = option.label.split(splitter);

		if (option.value === (value as string)) {
			return (
				<div className="withdraw-modal-bank-account-dropdown-row">
					<div className="withdraw-modal-bank-account-dropdown-values">
						<Typography
							className="withdraw-modal-bank-account-dropdown-value"
							theme="textMd"
						>
							{displayOptions[0]}
						</Typography>
						<Typography
							className="withdraw-modal-bank-account-dropdown-value-label"
							theme="textMd"
						>
							{displayOptions[1]}
						</Typography>
					</div>
					<FiCheck size={20} color="#888" />
				</div>
			);
		}

		return (
			<div className="withdraw-modal-bank-account-dropdown-row">
				<div className="withdraw-modal-bank-account-dropdown-values">
					<Typography
						className="withdraw-modal-bank-account-dropdown-value"
						theme="textMd"
					>
						{displayOptions[0]}
					</Typography>
					<Typography
						className="withdraw-modal-bank-account-dropdown-value-label"
						theme="textMd"
					>
						{displayOptions[1]}
					</Typography>
				</div>
			</div>
		);
	};

	const withdrawalTypeOptions = () => {
		if (withdrawOptions?.withdrawalTypes) {
			return withdrawOptions.withdrawalTypes.map(
				(withdrawalType: WithdrawalType) => {
					if (withdrawalType.value && withdrawalType.fee) {
						return {
							value: withdrawalType.value,
							label: withdrawalType.fee,
						};
					}
					return { label: "", value: "" };
				},
			);
		}
		return [{ label: "", value: "" }];
	};

	const withdrawalTypeItemTemplate = (
		option: DropdownOption,
		value: any,
	): ReactNode => {
		const withdrawOption = withdrawOptions?.withdrawalTypes?.find(
			(x) => x.value === option.value,
		);

		return (
			<div className="withdraw-modal-withdrawal-type-dropdown-row">
				<div className="withdraw-modal-withdrawal-type-dropdown-values">
					<Typography
						className="withdraw-modal-withdrawal-type-dropdown-value"
						theme="textMd"
					>
						{withdrawOption ? withdrawOption.name : option.value}
					</Typography>
					<Typography
						className="withdraw-modal-withdrawal-type-dropdown-value-label"
						theme="textMd"
					>
						{`Fee: ${option.label}`}
					</Typography>
				</div>
				{option.value === (value as string) && (
					<FiCheck size={20} color="#888" />
				)}
			</div>
		);
	};

	const withdrawalTypeValueTemplate = (
		option: DropdownOption,
		dropdownProps: DropdownProps,
	) => {
		if (option?.label && option?.value) {
			const withdrawOption = withdrawOptions?.withdrawalTypes?.find(
				(x) => x.value === option.value,
			);

			return (
				<div className="withdraw-modal-withdrawal-type-dropdown-values-selected">
					<Typography
						className="withdraw-modal-withdrawal-type-dropdown-value"
						theme="textMd"
					>
						{withdrawOption ? withdrawOption.name : option.value}
					</Typography>
					<Typography
						className="withdraw-modal-withdrawal-type-dropdown-value-label"
						theme="textMd"
					>
						{`Fee: ${option.label}`}
					</Typography>
				</div>
			);
		}

		return <>{dropdownProps.placeholder}</>;
	};

	const resetValues = () => {
		if (props.resetValues) {
			reset({
				bankAccount: undefined,
				amount: undefined,
				withdrawalType: undefined,
			});
		}
	};

	const onHandleSubmitValid = (data: WithdrawalForm) => {
		if (data?.amount && data?.bankAccount) {
			const selectedBank = withdrawalBankAccounts?.items?.find(
				(item) => item.accountNumber === data.bankAccount,
			);

			const selectedWithdrawOption = withdrawOptions?.withdrawalTypes?.find(
				(option) => option.value === DEFAULT_WITHDRAW_TYPE,
			);

			const amount = Number.parseFloat(data.amount.replace(/,/g, ""));

			const dataAsWithdrawalDetails: WithdrawalDetails = {
				accountNumber: selectedBank?.accountNumber,
				accountType: selectedBank?.accountType,
				amount: amount,
				bank: selectedBank?.bank,
				id: selectedBank?.id,
				withdrawalTypeFee: selectedWithdrawOption?.fee,
				withdrawalTypeName: selectedWithdrawOption?.name,
				withdrawalTypeValue: selectedWithdrawOption?.value,
			};

			props.onSubmit(dataAsWithdrawalDetails);
		}
	};

	const bankAccountInput: FormBuilderProps.FormInputProps[][] = [
		[
			{
				name: "bankAccount",
				className: "withdraw-modal-dropdown",
				options: bankAccountOptions(),
				placeholder: "Select an account",
				required: true,
				filter: true,
				showLabel: false,
				title: "",
				type: "dropdown-option",
				onChange: () => {
					props.onResetErrors?.();
					clearErrors("bankAccount");
				},
				panelClassName: "withdraw-modal-dropdown-panel",
				panelFooterTemplate: (
					<Button
						className={styles.addBankAccountButton}
						centered
						block
						onClick={props.onAddBankAccount}
						variant="tertiary"
					>
						<FiPlus size={20} color="inherit" />
						Add a bank account
					</Button>
				),
				iconColour: "#888",
				iconSize: 20,
				itemTemplate: bankAccountItemTemplate,
				valueTemplate: (
					option: DropdownOption,
					dropdownProps: DropdownProps,
				) => {
					if (option?.label && option?.value) {
						const displayOptions = option.label.split(splitter);
						return (
							<div className="withdraw-modal-bank-account-dropdown-values-selected">
								<Typography
									className="withdraw-modal-bank-account-dropdown-value"
									theme="textMd"
								>
									{displayOptions[0]}
								</Typography>
								<Typography
									className="withdraw-modal-bank-account-dropdown-value-label"
									theme="textMd"
								>
									{displayOptions[1]}
								</Typography>
							</div>
						);
					}

					return <>{dropdownProps.placeholder}</>;
				},
				dropdownRef: dropdownRef,
			},
		],
	];

	const amountInput: FormBuilderProps.FormInputProps[][] = [
		[
			{
				className: "withdraw-modal-account-number-input",
				name: "amount",
				hideAsterisk: true,
				placeholder: "0.00",
				inputMode: "decimal",
				required: true,
				showLabel: false,
				theme: "none",
				title: "Amount",
				type: "text",
				onBlur: handleBlur,
				onFocus: handleFocus,
				onChange: handleChangeAmount,
			},
		],
	];

	const withdrawalTypeInput: FormBuilderProps.FormInputProps[][] = [
		[
			{
				name: "withdrawalType",
				className: "withdraw-modal-dropdown",
				options: withdrawalTypeOptions(),
				placeholder: "Select a withdrawal type",
				required: true,
				showLabel: true,
				hideAsterisk: true,
				title: "Withdrawal Type",
				type: "dropdown-option",
				onChange: () => {
					props.onResetErrors?.();
					clearErrors("withdrawalType");
				},
				panelClassName: "withdraw-modal-dropdown-panel",
				iconColour: "#888",
				iconSize: 20,
				itemTemplate: withdrawalTypeItemTemplate,
				valueTemplate: withdrawalTypeValueTemplate,
				popover: (
					<MoreInfoTooltip name="Withdrawal Type" hasIcon maxWidth={320}>
						A Standard withdrawal will typically take 3 business days to reflect
						in your account, whereas a STP/Fast withdrawal typically takes 1-2
						business days.
					</MoreInfoTooltip>
				),
			},
		],
	];

	useEffect(() => {
		fetchWithdrawalInfo();
	}, [activeClientId]);

	useEffect(() => {
		resetValues();
	}, [props.resetValues]);

	const viewProps = {
		accountNumberInput: amountInput,
		bankAccountInput: bankAccountInput,
		errors,
		formControl: control,
		isFocused: isFocused,
		withdrawalTypeInput: withdrawalTypeInput,
		withdrawOptions,
		handleSubmit,
		onHandleSubmitValid,
	};

	return (
		<Dialog
			isOpen={props.isOpen}
			onClose={props.onClose}
			size="xl"
			className="withdraw-modal"
			title="Withdraw ZAR"
			description="Withdraw ZAR via a local EFT to your South African bank account."
			fullscreen={isMobile}
			showTopbar={isMobile}
			actions={
				<>
					<Button variant="secondary" onClick={props.onClose}>
						Cancel
					</Button>
					<Button form="withdraw-form" type="submit">
						Submit
					</Button>
				</>
			}
		>
			<form
				id="withdraw-form"
				onSubmit={viewProps.handleSubmit(viewProps.onHandleSubmitValid)}
				noValidate
			>
				<div className="content-container">
					<div className="withdraw-modal-bank-account-section">
						<div className="withdraw-modal-bank-account-title-section">
							<Typography className="withdraw-modal-title" theme="textLg">
								Bank Account
							</Typography>
							<Button
								variant="tertiary"
								className={styles.manageAccountsButton}
								onClick={props.onManageAccounts}
							>
								Manage accounts
							</Button>
						</div>
						<FormBuilder
							formTitle="withdrawForm"
							formControl={viewProps.formControl}
							errors={viewProps.errors}
							formInputs={viewProps.bankAccountInput}
						/>
					</div>
					<div className="withdraw-modal-withdrawal-type-title-section">
						<Typography className="withdraw-modal-title" theme="textLg">
							Withdrawal Details
						</Typography>
					</div>
					<div className="withdraw-modal-account-number-section">
						<Typography
							className={twMerge(
								"withdraw-modal-account-number-label",
								viewProps.isFocused ? "text-teal-550" : "text-gray-1100",
							)}
							theme="textSm"
						>
							Amount
						</Typography>
						<div className="withdraw-modal-account-number-input-section">
							<div className="withdraw-modal-account-number-input-prefix">
								ZAR
							</div>
							<FormBuilder
								formTitle="withdrawForm"
								formControl={viewProps.formControl}
								errors={viewProps.errors}
								formInputs={viewProps.accountNumberInput}
							/>
						</div>
					</div>
					<div className={styles.capitecFeeContainer}>
						<p className={styles.capitecFeeLabel}>
							Capitec Withdrawal Fee
							<MoreInfoTooltip
								name="Capitec Withdrawal Fee"
								hasIcon
								maxWidth={280}
							>
								This processing fee is charged by Capitec. We do not charge any
								fees/commission on top of this.
							</MoreInfoTooltip>
						</p>
						{viewProps.withdrawOptions?.withdrawalTypes?.find(
							(current) => current.value === DEFAULT_WITHDRAW_TYPE,
						)?.fee ?? DEFAULT_FEE}
					</div>
					<Note variant="full">
						Capitec currently does not facilitate RTC/fast EFTs. Withdrawals
						typically take 3 business days to reflect.
					</Note>
					{/* <FormBuilder
				formTitle="withdrawForm"
				formControl={viewProps.formControl}
				errors={viewProps.errors}
				formInputs={viewProps.withdrawalTypeInput}
			/> */}
				</div>
			</form>
		</Dialog>
	);
};
